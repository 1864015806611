<template>
    <div class="flex items-center justify-center w-full">
        <div class="modify">
            <!-- <div class="text-left text-gray-500 path sm:my-3">首页-个人中心-修改信息</div> -->
            <navbar-text :navbarList="navbarList"></navbar-text>
            <div>
                <div class="flex mt-8 duration-500 sm:justify-center equipment">
                    <el-form ref="form" label-width="80px" label-position="left">
                        <el-form-item label="头像" class="flex justify-start">
                            <!-- <el-upload
                                :action="qiniuUrl"
                                :on-preview="handlePictureCardPreview"
                                :on-remove="handleRemove"
                                :limit="1"
                                :auto-upload="false"
                                :on-success="uploadSuccess"
                                accept=".png, .jpg, .jpeg"
                                :data="uploadData"
                                ref="upload"
                                :multiple="true"
                            >
                                <div>上传头像</div>
                            </el-upload>-->
                            <el-upload class="avatar-uploader" :action="qiniuUrl" :show-file-list="false"
                                :on-success="uploadSuccess" :before-upload="beforeAvatarUpload" :data="uploadData">
                                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="用户名" class="flex justify-start">
                            <el-input v-model="userName" placeholder="请输入用户名" class="duration-500 form_input">
                            </el-input>
                        </el-form-item>

                        <el-form-item label="出生日期" class="flex justify-start">
                            <div class="pc_select_show">
                                <el-date-picker type="date" placeholder="请选择出生日期" v-model="dateOfBirth"
                                    class="duration-500 form_input" :picker-options="pickerOptions0"></el-date-picker>
                            </div>
                            <div class="phone_select_show">
                                <div class="flex form_input custom_input" @click="showDatePicker = true">
                                    <span :class="dateOfBirth == '' ? 'text-gray-300' : ''">{{
                                        dateOfBirth == '' ?
                                        '请选择出生日期' : dateOfBirth
                                    }}</span>
                                </div>
                            </div>
                        </el-form-item>
                        <el-form-item label="性别" class="flex justify-start">
                            <el-radio-group v-model="sex">
                                <el-radio label="男"></el-radio>
                                <el-radio label="女"></el-radio>
                            </el-radio-group>
                        </el-form-item>

                        <el-form-item label="." class="flex justify-start btn_item">
                            <el-button type="primary" @click="infoCheckRules" class="duration-500 btn">保存</el-button>
                            <!-- <el-button style="width:200px">解绑</el-button> -->
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
        <!-- moblie 日期选择 -->
        <custom-date-picker language="zh" :show-picker-model="showDatePicker" :start-date="new Date('1968-10-20')"
            :end-date="new Date()" @cancel="handleCancel" @confirm="handleConfirm"></custom-date-picker>

        <!-- 查看图片 -->
        <el-dialog :visible.sync="dialogVisible" append-to-body>
            <img width="100%" fit="contain" :src="dialogImageUrl" alt />
        </el-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            dateOfBirth: "",
            userName: "",
            sex: "男",
            showDatePicker: false,
            // 设置elementui日期选择器只能选择今日之前的日期
            pickerOptions0: {
                disabledDate(time) {
                    return time.getTime() > Date.now() - 8.64e6
                }
            },

            // 图片上传的相关属性
            uploadData: {
                token: ""
            },
            avatar: "",
            dialogVisible: false,
            dialogImageUrl: "",
            imageUrl: "",
            navbarList: [{ title: "个人中心", path: "/index/personal/message" }, { title: '修改信息', path: '' }]

        };
    },
    computed: {
        ...mapGetters(['getQiniuToken', 'getQinniuUrl', 'getUserInfo'])
    },

    created() {
        // 获取七牛token
        this.$store.dispatch("getAccessToken", this.$EventBus)
        // this.uploadData.token = this.getQiniuToken
        this.$store.dispatch("aquireUserInfo")

    },
    watch: {
        "getUserInfo": function () {
            this.userName = this.getUserInfo.userName
            this.dateOfBirth = this.getUserInfo.dateOfBirth
            this.sex = this.getUserInfo.sex == 1 ? '男' : this.getUserInfo.sex == 0 ? "男" : "女"
            this.imageUrl = this.getUserInfo.userPicture
        },
        "getQiniuToken": function () {
            this.uploadData.token = this.getQiniuToken
        }
    },
    methods: {
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG(PNG) 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        // 上传成功
        uploadSuccess(res, file) {
            this.avatar = res
            this.imageUrl = URL.createObjectURL(file.raw);
        },

        // 信息校验
        infoCheckRules() {
            if (this.imageUrl == "") {
                this.$EventBus.$emit("toast", { type: 'error', message: '请上传头像' })
                return
            }
            if (this.isNull(this.userName)) {
                this.$EventBus.$emit("toast", { type: 'error', message: '请填写用户名' })
                return
            }
            // console.log('this.dateOfBirth', this.dateOfBirth, this.isNull(this.dateOfBirth), this.dateOfBirth == '', this.dateOfBirth == null)
            if (this.isNull(this.dateOfBirth) || this.dateOfBirth == '' || this.dateOfBirth == null) {
                this.$EventBus.$emit("toast", { type: 'error', message: '请选择出生日期' })
                return
            }
            // console.log(typeof this.dateOfBirth)

            // console.log(this.dateOfBirth, "转换后")
            this.onSubmit()
        },

        onSubmit() {
            let data = {
                userName: this.userName,
                userid: this.getUserInfo.userId,
                sex: this.sex == "男" ? 1 : 2
            }
            if (typeof this.dateOfBirth == "object") {
                this.dateOfBirth = this.transformDate(this.dateOfBirth, 'simple', '-')
            }

            data.dateOfBirth = this.dateOfBirth

            if (this.imageUrl == this.getUserInfo.userPicture) {
                data.userPicture = this.imageUrl
            } else {
                data.userPicture = this.getQinniuUrl + "/" + this.avatar.key
            }
            this.$request({
                method: "put",
                url: this.$requestPath.userUpdate,
                data,
            }).then(res => {
                console.log("更新用户信息", res)
                if (res.code == 0) {
                    this.$store.dispatch("aquireUserInfo")
                    this.$EventBus.$emit("toast", { type: 'success', message: '信息更新成功' })
                } else {
                    this.$EventBus.$emit("toast", { type: 'error', message: res.msg })
                }
            }).catch(error => {
                console.log("更新用户信息错误", error)
            })
        },
        handleCancel() {
            this.showDatePicker = false;
        },
        handleConfirm(item) {
            console.log(item)
            this.selectedDate = item;
            this.showDatePicker = false;
            this.dateOfBirth = item
        },
        disableDate(item) {
            if (
                new Date(item) - new Date("2019-8-10") >= 0 &&
                new Date("2019-8-20") - new Date(item) >= 0
            ) {
                return true;
            }
            return false;
        },
    },
};
</script>


<style lang='scss' scoped>
.modify {
    width: 100%;
    max-width: var(--max-width);
    padding: 0 var(--margin-base);
    min-height: var(--min-height);
}

@media screen and (max-width: 640px) {
    .path {
        margin-top: 0.625rem;
    }

    .form_input {
        width: 15.625rem;
    }

    .btn {
        width: 6.25rem;
    }
}

@media screen and (max-width: 900px) {
    .pc_show {
        display: none;
    }

    .title {
        font-size: 1.25rem;
    }
}

@media screen and (min-width: 640px) {
    .form_input {
        width: 18.75rem;
    }

    .btn {
        width: 12.5rem;
    }
}

@media screen and (min-width: 900px) {
    .pc_show {
        display: block;
    }

    .title {
        font-size: 1.875rem;
    }
}
</style>
<style lang="scss">
.btn_item label {
    opacity: 0;
}

.equipment .el-form-item__content {
    margin-left: 0.625rem !important;
}

.avatar-uploader .el-upload {
    border: 0.0625rem dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

@media screen and (max-width: 640px) {
    .avatar-uploader-icon {
        font-size: 1.75rem;
        color: #8c939d;
        width: 6.25rem;
        height: 6.25rem;
        line-height: 6.25rem;
        text-align: center;
    }

    .avatar {
        width: 6.25rem;
        height: 6.25rem;
        display: flex;
        justify-content: center;
        align-content: center;
    }
}

@media screen and (min-width: 640px) {
    .avatar-uploader-icon {
        font-size: 1.75rem;
        color: #8c939d;
        width: 148px;
        height: 148px;
        line-height: 148px;
        text-align: center;
    }

    .avatar {
        width: 148px;
        height: 148px;
        display: block;
    }
}
</style>